// extracted by mini-css-extract-plugin
export var backgroundImage = "recipe-meta-module--backgroundImage--17ccc";
export var buttonIcon = "recipe-meta-module--button-icon--bb4db";
export var buttonsContainer = "recipe-meta-module--buttons-container--700f3";
export var categoryContainer = "recipe-meta-module--category-container--4a817";
export var categoryPosition = "recipe-meta-module--category-position--ec6d9";
export var description = "recipe-meta-module--description--96f90";
export var errorBlink = "recipe-meta-module--error-blink--0fc62";
export var grid = "recipe-meta-module--grid--a8e55";
export var imagesContainer = "recipe-meta-module--images-container--b5471";
export var mainImage = "recipe-meta-module--mainImage--69c51";
export var mainImageRatio = "recipe-meta-module--main-image-ratio--3aacc";
export var miscContainer = "recipe-meta-module--misc-container--5f888";
export var proposition = "recipe-meta-module--proposition--3bf6d";
export var summary = "recipe-meta-module--summary--0bdde";
export var title = "recipe-meta-module--title--ebf5a";
export var titleContainer = "recipe-meta-module--title-container--c6dcc";
export var titleFirstLine = "recipe-meta-module--title-first-line--d9844";
export var titleRest = "recipe-meta-module--title-rest--99ee2";